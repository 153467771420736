const getApiEndpoint = () => {
    const isLocalhost = window.location.hostname === "localhost";
  
    const sandboxEndpoint = "http://localhost:8000";
    const productionEndpoint = "https://api-bandit-wagons-694be59ee6e4.herokuapp.com";
  
    return isLocalhost ? sandboxEndpoint : productionEndpoint;
  };
  
  const API_ENDPOINT = getApiEndpoint();
  
  export default API_ENDPOINT;
  