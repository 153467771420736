import React, { useCallback, useEffect, useState } from 'react'
import DefaultLayout from '../../layout/DefaultLayout';
import BankIdTable from '../../components/Tables/BankIdTable';
import { useSocket } from '../../socketContext';
import API_ENDPOINT from '../../common/api/api';
import { SessionData } from '../../types/session';
import notifySound from "../../sounds/notify.mp3"

interface ErrorState {
  message: string; // Or customize to match your error structure
}

const BANKID = () => {
  const socket = useSocket();
  const [sessions, setSessions] = useState<SessionData[]>([]);
  const [error, setError] = useState<ErrorState | null>(null);
  const [isLoading, setIsLoading] = useState(false);


  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        API_ENDPOINT+'/api/session/get',
      );
      //console.log(response.json());
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data: SessionData[] = await response.json();
      console.log(data)
      setSessions(data);
      setError(null);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError({ message: 'Error fetching sessions ' + error.message });
      } else {
        setError({ message: 'Unknown error occurred' });
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(); // Initial data fetch

    if (socket) {
      const handleClientsEvent = () => {
        fetchData();
        playNotificationSound();
      };

      socket.on('bankid', handleClientsEvent);

      return () => {
        socket.off('bankid', handleClientsEvent);
      };
    }
  }, [socket, fetchData]);

  const playNotificationSound = () => {
    const audio = new Audio(notifySound);
    audio.play();
  };

  return (
    
    <DefaultLayout>
        <div>
        <BankIdTable
          bank={'BankId'}
          from={0}
          sessions={sessions}
          fetchData={fetchData}
        />
        
        </div>
    </DefaultLayout>
  )
}

export default BANKID