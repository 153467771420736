import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Vonage } from '@vonage/server-sdk';

interface Account {
  masterApiKey: string;
  secretApiKey: string;
  name: string;
}

interface Balance {
  name: string;
  balance: string;
}

const VonageAccounts: React.FC<{ accounts: Account[] }> = ({ accounts }) => {
  const [balances, setBalances] = useState<Balance[]>([]);

  //   const fetchAccountBalance = async (apiKey: string, apiSecret: string): Promise<string> => {
  //   try {
  //     // Initialize the Vonage client with your API key and secret
  //     const vonage = new Vonage({ apiKey, apiSecret });

  //     // Fetch the account balance
  //     const balanceResponse = await vonage.account.checkBalance();

  //     // Extract the balance value from the response
  //     const balance = balanceResponse.value;

  //     return balance;
  //   } catch (error) {
  //     console.error('Error fetching account balance:', error);
  //     return 'Error';
  //   }
  // };

  // const fetchBalances = async () => {
  //   const newBalances: Balance[] = [];
  //   for (const account of accounts) {
  //     const balance = await fetchAccountBalance(
  //       account.masterApiKey,
  //       account.secretApiKey,
  //     );
  //     newBalances.push({ name: account.name, balance });
  //   }
  //   setBalances(newBalances);
  // };

  // useEffect(() => {
  //   // Fetch balances initially
  //   fetchBalances();

  //   // Set up interval to periodically fetch balances
  //   const intervalId = setInterval(fetchBalances, 60000); // Fetch every minute

  //   // Clear interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);
  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="py-6 px-4 md:px-6 xl:px-7.5">
        <h4 className="text-xl font-semibold text-black dark:text-white">
          VONAGE ACCOUNTS
        </h4>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Account Name
              </th>
              <th scope="col" className="px-6 py-3">
                Balance
              </th>
              <th scope="col" className="px-6 py-3">
                ACTION
              </th>
            </tr>
          </thead>

          <tbody>
            {balances.map((account, key) => (
              <tr
                className=" border-t border-stroke py-4.5 px-4 dark:border-strokedark  md:px-9 2xl:px-7.5"
                key={key}
              >
                <td className="px-6 py-4">{account.name}</td>
                <td className="px-6 py-4">{account.balance}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VonageAccounts;
