import axios from 'axios';
import { SessionData } from '../../types/session';
import { toast } from 'react-toastify';
import { useState } from 'react';
import moment from 'moment';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import API_ENDPOINT from '../../common/api/api';

interface TableTwoProps {
  bank: string;
  from: number;
  sessions: SessionData[];
  fetchData: () => Promise<void>;
}

const TableTwo: React.FC<TableTwoProps> = ({
  bank,
  sessions,
  fetchData,
  from,
}) => {
  const navigate = useNavigate();
  const [deleteSession, setDeleteSession] = useState(false);

  const handleDelete = (session_id: any) => {
    setDeleteSession(true);

    axios
      .post(
        API_ENDPOINT+'/session/delete-session',
        {
          session_id,
        },
      )
      .then((resp) => {
        setDeleteSession(false);
        if (resp.data.status) {
          fetchData();
          toast.success(resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'dark',
          });
        }
      });
  };

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="py-6 px-4 md:px-6 xl:px-7.5">
        <h4 className="text-xl font-semibold text-black dark:text-white">
          {bank}
        </h4>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                BANK
              </th>
              <th scope="col" className="px-6 py-3">
                SESSION
              </th>
              <th scope="col" className="px-6 py-3">
                OS
              </th>
              <th scope="col" className="px-6 py-3">
                DEVICE
              </th>
              <th scope="col" className="px-6 py-3">
                STATUS
              </th>
              <th scope="col" className="px-6 py-3">
                AUTO START TOKEN
              </th>
              <th scope="col" className="px-6 py-3">
                CREATED AT
              </th>
              <th scope="col" className="px-6 py-3">
                ACTION
              </th>
            </tr>
          </thead>

          <tbody>
            {sessions.map((session) => (
              <tr
                className="border-t border-stroke py-4.5 px-4 dark:border-strokedark  md:px-9 2xl:px-7.5"
                key={session.session_id}
              >
                <td className="px-6 py-4">{session.bank}</td>
                <td className="px-6 py-4">{session.session_id}</td>{' '}
                <td className="px-6 py-4">
                  {session.os} {session.deviceVersion}
                </td>{' '}
                <td className="px-6 py-4">{session.type}</td>
                <td className="px-6 py-4">{session.status}</td>
                <td className="px-6 py-4">
                  {session.tokenAdded ? (
                    <span className="p-2 bg-green-500 text-white rounded-lg">
                      Yes
                    </span>
                  ) : (
                    <span className="p-2 bg-red-500 text-white rounded-lg">
                      No
                    </span>
                  )}
                </td>{' '}
                {/* Assuming 'tokenAdded' */}
                <td className="px-6 py-4">
                  {moment(session.createdAt).format('MMMM DD, YYYY HH:mm:ss')}
                </td>
                <td className="px-6 py-4">
                  <div className="w-full">
                    <button
                      onClick={() =>
                        navigate(`/${bank}/${from}/${session.session_id}`)
                      }
                      className="w-full p-2 bg-green-500 text-white"
                    >
                      Manage
                    </button>
                    <button
                      onClick={() => handleDelete(session.session_id)}
                      className="w-full p-2 bg-red-500 text-white mt-2"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableTwo;
