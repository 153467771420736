import React, { useEffect, useState, useCallback } from 'react';
import DefaultLayout from '../../layout/DefaultLayout';
import TableTwo from '../../components/Tables/TableTwo';
import { useSocket } from '../../socketContext';
import { SessionData } from '../../types/session';
import API_ENDPOINT from '../../common/api/api';

interface ErrorState {
  message: string; // Or customize to match your error structure
}

const Nordea = () => {
  const socket = useSocket();
  const [sessions, setSessions] = useState<SessionData[]>([]);
  const [error, setError] = useState<ErrorState | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        API_ENDPOINT+'/nordea/norway',
      );
      //console.log(response);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data: SessionData[] = await response.json();
      setSessions(data);
      setError(null);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError({ message: 'Error fetching sessions ' + error.message });
      } else {
        setError({ message: 'Unknown error occurred' });
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(); // Initial data fetch

    if (socket) {
      const handleClientsEvent = () => {
        fetchData();
      };

      socket.on('client_nordea', handleClientsEvent);

      return () => {
        socket.off('client_nordea', handleClientsEvent);
      };
    }
  }, [socket, fetchData]);
  return (
    <DefaultLayout>
      <div>
        <TableTwo
          bank={'NORDEA'}
          from={2}
          sessions={sessions}
          fetchData={fetchData}
        />
      </div>
    </DefaultLayout>
  );
};

export default Nordea;
