import DefaultLayout from '../../layout/DefaultLayout';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

const FORMAT = () => {
  const [inputNumbers, setInputNumbers] = useState('');
  const [formattedNumbers, setFormattedNumbers] = useState('');

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setInputNumbers(event.target.value);
  };

  const formatNumbers = () => {
    const lines = inputNumbers.split('\n');
    const formatted = lines
      .map((line) => {
        const formattedNumber = line.trim().replace(/[^0-9]/g, '').replace(/^0/, ''); // Remove leading '0' and trim
        return formattedNumber ? `${formattedNumber}` : ''; // Only prepend '47' if the line is not empty
      })
      .filter((number) => number) // Filter out any empty strings
      .join(','); // Join the numbers with a comma
    setFormattedNumbers(formatted);
  };


  const copyToClipboard = () => {
    navigator.clipboard.writeText(formattedNumbers);
    toast.success('Copied to clipboard!', {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'dark',
    });
  };

  const clearForm = () => {
    setInputNumbers('');
    setFormattedNumbers('');
  };

  return (
    <DefaultLayout>
      <div>
        <div className="">
          <div>
            <div className="w-full mx-auto p-4 bg-white dark:bg-slate-700 rounded-md shadow-md">
              <h2 className="text-xl font-semibold mb-4">
                Phone Number Formatter
              </h2>
              <textarea
                className="w-full p-2 mb-4 border rounded-md dark:bg-slate-700"
                placeholder="Enter phone numbers separated by new lines"
                value={inputNumbers}
                onChange={handleInputChange}
                rows={3}
                cols={30}
              />
              <br />
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-2"
                onClick={formatNumbers}
              >
                Format Numbers
              </button>
              <button
                className="bg-green-700 text-white px-4 py-2 rounded-md hover:bg-green-700 mr-2"
                onClick={copyToClipboard}
                disabled={!formattedNumbers}
              >
                Copy to Clipboard
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
                onClick={clearForm}
              >
                Reset
              </button>
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">
                  Formatted Numbers:
                </h3>
                <p className="break-all">{formattedNumbers}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default FORMAT;
