import DefaultLayout from '../../layout/DefaultLayout';
import TableTwo from '../../components/Tables/TableTwo';
import VonageAccounts from '../../components/Tables/VonageAccounts';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSocket } from '../../socketContext';
import API_ENDPOINT from '../../common/api/api';

const Vonage = () => {
  const socket = useSocket();
  const [name, setName] = useState('');
  const [master, setMaster] = useState('');
  const [secret, setSecret] = useState('');
  const [email, setEmail] = useState('');
  const [account, setAccount] = useState([]);
  const [loading, setLoading] = useState(false)

  const submitData = () => {
    setLoading(true)
    axios
      .post(API_ENDPOINT+'/vonage/create', {
        profile: name,
        master,
        secret,
        email,
      })
      .then((resp) => {
        setLoading(false)
        console.log(resp.data);
      });
  };

  const fetchData = () => {
    axios
      .get(API_ENDPOINT+'/vonage/')
      .then((resp) => {
        //console.log(resp.data);
        setAccount(resp.data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('balancesUpdated', () => {
        fetchData();
      });

      return () => {
        socket.off('balancesUpdated');
      };
    }
  }, [socket]);

  return (
    <DefaultLayout>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <VonageAccounts accounts={account && account} />
        </div>
        <div className="col-span-3 lg:col-span-1">
          <div className="bg-boxdark p-2 rounded-sm px-4">
            <p className="font-bold dark:text-white text-xl">Add Account</p>
            <div className="mt-6">
              <div className="py-4">
                <label>Account Name</label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  className="w-full border border-gray-200 rounded-sm bg-boxdark p-2 mt-2"
                />
              </div>
              <div className="py-4">
                <label>Master</label>
                <input
                  onChange={(e) => setMaster(e.target.value)}
                  className="w-full border border-gray-200 rounded-sm bg-boxdark p-2 mt-2"
                />
              </div>
              <div className="py-4">
                <label>Secret</label>
                <input
                  onChange={(e) => setSecret(e.target.value)}
                  className="w-full border border-gray-200 rounded-sm bg-boxdark p-2 mt-2"
                />
              </div>

              <div className="py-4">
                <label>Email</label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full border border-gray-200 rounded-sm bg-boxdark p-2 mt-2"
                />
              </div>
              <div className="py-4">
                <button
                  onClick={submitData}
                  className="w-full p-2 text-white bg-blue-500 rounded-sm"
                >
                  {
                    loading ? (
                      "Adding..."
                    ): ("Add Account")
                  }
                  
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Vonage;
