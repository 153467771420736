import React, { useState, ReactNode, useEffect } from 'react';
import Header from '../components/Header/index';
import Sidebar from '../components/Sidebar/index';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_ENDPOINT from '../common/api/api';

const DefaultLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [secure, setSecure] = useState(false);
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [token, setToken] = useState('');

  useEffect(() => {
    // setLoading(true);
    const mockToken = localStorage.getItem('token');
    if (mockToken) {
      setToken(mockToken);
    } else {
      navigate('/auth/signin');
    }
  }, []);

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token]);

  const checkToken = async () => {
    try {
      const response = await axios.get(
        API_ENDPOINT+'/login/secure-token',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const status = response.status;
      //console.log(response);
      if (status === 200) {
        setSecure(true);
      } else {
        localStorage.clear();
        navigate('/auth/signin');
      }
    } catch (error) {
      localStorage.clear();
      navigate('/auth/signin');
      console.error(error);
    }
  };

  const logOut = () => {
    localStorage.clear();
    navigate('/auth/signin');
  };

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* <!-- ===== Header Start ===== --> */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
              {children}
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
};

export default DefaultLayout;
