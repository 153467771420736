import React, { useState } from 'react';
import DefaultLayout from '../../layout/DefaultLayout';

interface PhoneNumberData {
  telco: string;
  phoneNumber: string;
  carrier: {
    name: string;
  };
  phone_number: string;
}

const SORT: React.FC = () => {
  const [inputNumbers, setInputNumbers] = useState<string>('');
  const [groupedNumbers, setGroupedNumbers] = useState<
    { telco: string; numbers: string[] }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const groupNumbers = async () => {
    setLoading(true);
    try {
      const promises = inputNumbers.split(',').map(async (phoneNumber) => {
        const response = await fetch(
          `https://lookups.twilio.com/v1/PhoneNumbers/+${phoneNumber.trim()}?Type=carrier`,
          {
            headers: {
              Authorization:
                'Basic ' +
                btoa(
                  'AC9d9cffaffc085ca50d39581cbd58bb8c:a56aa7247def7fe1940df5e23179f6e1',
                ), // Replace with your Twilio credentials
            },
          },
        );
        const data: PhoneNumberData = await response.json();
        return {
          telco: data.carrier.name,
          phoneNumber: data.phone_number,
        };
      });

      const grouped = await Promise.all(promises);
      const telcoMap: { [telco: string]: string[] } = {};

      grouped.forEach(({ telco, phoneNumber }) => {
        if (!telcoMap[telco]) {
          telcoMap[telco] = [];
        }
        telcoMap[telco].push(phoneNumber);
      });

      setGroupedNumbers(
        Object.entries(telcoMap).map(([telco, numbers]) => ({
          telco,
          numbers,
        })),
      );
    } catch (error) {
      console.error('Error grouping numbers:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <DefaultLayout>
      <div>
        <div className="">
          <div>
            <div className="w-full mx-auto p-4 bg-white dark:bg-slate-700 rounded-md shadow-md">
              <h2 className="text-xl font-semibold mb-4">Sort Numbers</h2>
              <div className="container mx-auto p-4">
                <textarea
                  className="w-full px-3 py-2 mb-4 border dark:bg-slate-700 rounded-md focus:outline-none focus:border-blue-500"
                  value={inputNumbers}
                  onChange={(e) => setInputNumbers(e.target.value)}
                  placeholder="Enter comma-separated phone numbers"
                  rows={5}
                  cols={50}
                />
                <button
                  className="block w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none"
                  onClick={groupNumbers}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Group Numbers'}
                </button>
                <div className="mt-4">
                  {groupedNumbers.length > 0 && (
                    <div>
                      <h2 className="mb-2 text-lg font-semibold">
                        Grouped Numbers:
                      </h2>
                      <ul>
                        {groupedNumbers.map((group, index) => (
                          <li key={index} className="mb-1">
                            <strong>{group.telco}</strong> -{' '}
                            {group.numbers.join(', ')}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default SORT;
